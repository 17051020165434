import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { UserInfo } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./log-errors.service";
import * as i4 from "./consultas.service";
import * as i5 from "./bscript.service";
export class UserAuthenticationService {
    constructor(http, _localStorageService, _logs, _consulta, security) {
        this.http = http;
        this._localStorageService = _localStorageService;
        this._logs = _logs;
        this._consulta = _consulta;
        this.security = security;
        this.userState = new EventEmitter();
    }
    getUser() {
        const userData = this._localStorageService.get('DataToRegister');
        return (userData && userData.DataUserSae) || null;
    }
    getUsers() {
        const userData = this._localStorageService.get('DataToRegister');
        return (userData ? userData.DataUser : []).filter(userItem => userItem.status_contrato !== "ANULADO");
    }
    selectUser(nro_contrato) {
        const users = this.getUsers();
        const user = users.find(userItem => userItem.nro_contrato === nro_contrato);
        if (user) {
            const userData = this._localStorageService.get("DataToRegister");
            this._localStorageService.set("DataToRegister", Object.assign({}, userData, { DataUserSae: user }));
            return user;
        }
    }
    registerUser(userInfo) {
        // this._localStorageService.removeAll();
        // this._createAccount(userInfo);
        this.login({ email: userInfo.email, password: userInfo.password, telefono: userInfo.phone });
        // TODO: STEVEN
        // this.userState.emit({ ...userInfo });
        return true;
    }
    // _createAccount(userInfo: UserInfo) {
    //   let user: any = userInfo
    //   const { identidad, nro_contrato } = user
    //   this._localStorageService.set('user', { ...userInfo});
    //   this._localStorageService.set('cedulaCliente', {
    //     Cedula: `${identidad} (${nro_contrato})`,
    //     NContrato: nro_contrato,
    //     id:1,
    //     identidad
    //   });
    // }
    noRegisterdUser() {
        const users = this.getUser();
        if (!users) {
            return true;
        }
        return false;
    }
    login(userInfo) {
        const registeredUser = this.getUser();
        if (!registeredUser) {
            return false;
        }
        // const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
        const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase());
        const validPhoneUser = (userInfo.telefono === registeredUser.telefono);
        if (!validUser && !validPhoneUser) {
            return false;
        }
        this._localStorageService.set('auth-user', userInfo);
        return true;
    }
    modifyUser(userInfo) {
        const info = this._localStorageService.get("DataToRegister");
        let userInfoUpdate = info.DataUserSae = Object.assign({}, info.DataUserSae, userInfo);
        info.DataUser = info.DataUser.map((user) => {
            return user.id_contrato === userInfoUpdate.id_contrato ? userInfoUpdate : user;
        });
        this._localStorageService.set("DataToRegister", info);
        this.userState.emit(userInfoUpdate);
    }
    logout() {
        this._localStorageService.remove('auth-user');
    }
    loginCRM(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
            try {
                const responseCliente = yield this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
                if (!responseCliente) {
                    return { status: false };
                }
                this._localStorageService.set('HaveFibexP', responseCliente.fibex_play);
                return responseCliente;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return { status: false };
            }
        });
    }
    resetPassword(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    email: null,
                    password: null,
                };
            }
        });
    }
    UpdateEmail(Cedula, Email) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.http.get(`${environment.apiCRM}UpdateEmail/?ci=${Cedula}&email=${Email}`).subscribe((ResUp) => {
                    resolve(ResUp);
                }, (erro) => {
                    reject(erro);
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    AcualizarDatos(Cedula, Email, Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const consultaData = yield this._consulta.GetSaldoCedula(Cedula).then((response) => {
                    const body = {
                        "Cedula": Cedula,
                        "id_Contrato": response[0].id_contrato,
                        "Email": Email,
                        "Lic": "584120202020@c.us",
                        "Telefono": Phone
                    };
                    // Encabezados de la petición
                    const headersData = {
                        directory: "SAE",
                        method: `ActualizarDatos`,
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    this.security.EncrypDataHash(headersData).then((headers) => {
                        this.security.EncrypDataHash(body).then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResLog) => {
                                resolve(this.security.Descryp(ResLog));
                            }, err => { console.error(err); });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    verifyCedula(cedula) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    cedula: null,
                };
            }
        });
    }
    querySecurityQuestions(email, opTipe = 2, preguntaUno = "", respuestaUno = "", preguntaDos = "", respuestaDos = "") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiSecurityQuestions}email=${email}&opTipe=${opTipe}&preguntaUno=${preguntaUno}&respuestaUno=${respuestaUno}&preguntaDos=${preguntaDos}&respuestaDos=${respuestaDos}`).toPromise();
                if (!response.status) {
                    return {
                        status: false,
                        message: response.message,
                        data: null,
                    };
                }
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    message: "Las preguntas de seguridad no pudieron ser consultadas.",
                    data: null,
                };
            }
        });
    }
}
UserAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthenticationService_Factory() { return new UserAuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i4.ConsultasService), i0.ɵɵinject(i5.SeguridadDatos)); }, token: UserAuthenticationService, providedIn: "root" });

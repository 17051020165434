import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import axios from "axios";
import { MiEquipoService } from './mi-equipo.service';
import { dbFullDataBase } from '../lib/dbfullv2';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { MenuController, NavController } from '@ionic/angular';
import { ClearCacheService } from './clear-cache.service';
import { OneSignalService } from './one-signal.service';
import { IUserSae } from './user-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./bscript.service";
import * as i3 from "./log-errors.service";
import * as i4 from "./mi-equipo.service";
import * as i5 from "./local-storage.service";
import * as i6 from "@ionic/angular";
import * as i7 from "./one-signal.service";
import * as i8 from "./clear-cache.service";
export class ConsultasService {
    constructor(
    // private push: PushMessagesService,
    http, security, _logs, enc, miEquipoServ, _localStorageService, 
    // private authentication: UserAuthenticationService,
    menu, 
    // private _routing: RoutingAppService,
    onesignal, cache, navCtrl) {
        this.http = http;
        this.security = security;
        this._logs = _logs;
        this.enc = enc;
        this.miEquipoServ = miEquipoServ;
        this._localStorageService = _localStorageService;
        this.menu = menu;
        this.onesignal = onesignal;
        this.cache = cache;
        this.navCtrl = navCtrl;
        this.ChangeEmail = new EventEmitter();
        this.data$ = new EventEmitter(); // EventEmitter para notifcar
        this.MenuPersonalized = new EventEmitter();
        this.notifications = [];
        this.Serial = "";
        this.dbfull = new dbFullDataBase('saeplus_conexven');
        this.dbfull2 = new dbFullDataBase('thomas_colas');
        this.logoutEmitter = new EventEmitter();
        this.menuOpcionesReference = [];
    }
    GetUpgradeConcurrenceToday(nro_abonado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM thomas_estadistica.tmUpgradeConcurrence ca WHERE DATE(NOW()) = DATE(createdAt) and nro_abonado='${nro_abonado}'`
                    }
                });
                //   console.log(result.data[0])
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    AddUpgradeConcurrence(nro_abonado, upgraded) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.GetUpgradeConcurrenceToday(nro_abonado)
                    .then((upgrades) => {
                    console.log("Add upgrade concurrence ::::::::::::::::::::", upgrades);
                    // verificar si existe algun otro upgrade anterior, y determinar si no estamos guardando un upgrade exitoso
                    // ya que si es asi, es obligatorio almacenar su estadistica
                    if (upgrades.length && !upgraded) {
                        return resolve();
                    }
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'TokenAuthPlataform': environment.TokenBackendFull,
                            'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                            'db': this.security.encrypt('thomas_estadistica'),
                            'table': this.security.encrypt('tmUpgradeConcurrence'),
                            'x-keys-to-add-id': '[]',
                            'x-keys-of-arrays': '[]',
                            'x-relations': 'false',
                        })
                    };
                    const Body = {
                        nro_abonado,
                        id_serv: upgraded && upgraded.id_serv,
                        nombre_serv: upgraded && upgraded.nombre_serv,
                        upgraded: !!upgraded
                    };
                    this.http.post(`${environment.ApiBackendThomas}create-info`, Body, httpOptions)
                        .subscribe((Res) => {
                        resolve();
                    }, error => {
                        reject(error);
                    });
                })
                    .catch(reject);
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    EmiterChangeEmail(data) {
        this.ChangeEmail.emit(data);
    }
    EmiterNotify(data) {
        this.data$.emit(data);
    }
    UseApp(Datos) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegisterUseApp",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData).then((headers) => {
                    this.security.EncrypDataHash(Datos).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                            resolve(this.security.Descryp(data));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetAvatar(Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `GetAvatar`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Phone: Phone,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((Res) => {
                        resolve(this.security.Descryp(Res));
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetPinEmergencia(MesActual) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `PinEmergencia`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Mes: MesActual,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((Res) => {
                        resolve(this.security.Descryp(Res));
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    ServerDisponibles(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ServerFree`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    Manuales() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `Manuales_Publicos`,
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    VerificacionFibexPlay(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "FibexPlay",
                    method: `ClientFP`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetSaldoCedula(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `SaldoCe`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        data = this.security.Descryp(data);
                        const SaldoD = JSON.parse(data[0]);
                        if (SaldoD.data.info.length > 0) {
                            resolve(SaldoD.data.info);
                        }
                        else {
                            resolve(null);
                        }
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetSaldoContrato(NroContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `SaldoCo`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: NroContrato,
                    lic: environment.Lic
                };
                console.log("NroContrato");
                console.log(NroContrato);
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        console.log(data);
                        //          resolve(data);
                        data = this.security.Descryp(data);
                        //   console.log(data)
                        const DataSaldo = JSON.parse(data[0]);
                        if (DataSaldo && DataSaldo.data.info.length > 0) {
                            resolve(DataSaldo && DataSaldo.data.info[0]);
                        }
                        else {
                            resolve(null);
                        }
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    InfoContact() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
            query {
              InfoContact(
                token:"${environment.Token}" 
                lic:"${environment.Lic}"
              ){
                lic
                Content
              }
            }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.InfoContact);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                console.error(error);
            }
        }));
    }
    InfoEquiposClientes(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `InfoEquipos`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const result = yield axios.get(environment.URLApi, { headers });
                    console.log("Response info equipos:", result);
                    if (result.status === 200) {
                        result.data = this.security.Descryp(result.data);
                        const Info = JSON.parse(result.data[0]);
                        resolve(Info.data && Info.data.info);
                    }
                    // Núcleo de la peticion
                    // this.http.get(environment.URLApi, { headers }).subscribe(data => {
                    // data = this.security.Descryp(data)
                    // const Info = JSON.parse(data[0])
                    // resolve(Info.data?.info)
                    // }, (error: any) => {
                    //     this._logs.insertarError(error.message);
                    //     reject(error)
                    // })
                }))
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    DisEventMenu(DatosAbonado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let info = yield this.getEquipoInfo(DatosAbonado);
            console.log('', 'is wifi model compatible', info);
            if (info) {
                this.MenuPersonalized.emit(true);
            }
            else {
                this.MenuPersonalized.emit(false);
            }
        });
    }
    getEquipoInfo(DatosAbonado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (DatosAbonado && DatosAbonado.cedula) {
                    const cedula = DatosAbonado.cedula;
                    const id_contrato = DatosAbonado.id_contrato;
                    const responseInfo = yield this.InfoEquiposClientes(cedula);
                    const idsContrato = responseInfo.map((item) => item.id_contrato);
                    const indexEquip = idsContrato.indexOf(id_contrato) > -1 ? idsContrato.indexOf(id_contrato) : 0;
                    if (!responseInfo.length || responseInfo[indexEquip].sistema !== "SMARTOLT" || !responseInfo[indexEquip].paquetes) {
                        return false;
                    }
                    this.Serial = responseInfo[indexEquip].codigo_es;
                    const { onu_details } = yield this.miEquipoServ.getInfo(`get_onu_details/${this.Serial}`);
                    if (onu_details.onu_type_name !== 'EG8141A5') {
                        return false;
                    }
                    return true;
                }
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    InfoServicesClientes(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServiciosCliente`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    cedula: Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetFacturasCliente(NroContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `FacturaEdoCuentaCo`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: NroContrato,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        data = this.security.Descryp(data);
                        const Info = JSON.parse(data[0]);
                        if (Info.success != false && Info.success != "false") {
                            resolve(Info.data.info);
                        }
                        else {
                            resolve([]);
                        }
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    TurBoUpgrade(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let DataAPI = {
                    "id_det_orden": '626A92B0BCE6B4130441',
                    "detalle_orden": `Solicitud de ${Data.Accion} a ${Data.Plan}`,
                    "id_contrato": Data.id_contrato,
                    "Lic": environment.Lic
                };
                const DataQuery = {
                    query: `
          query {
            UpgrateTurboClient(
              token:"${environment.Token}"
              Data:{
                Cedula:"${Data.identidad}"
                idDevice:"${Data.codigo_es}"
                Accion:"${Data.Accion}"
                OldPlan:"${Data.PlanActual}"
                NewPlan:"${Data.Plan}"
                Status:${Data.Status || 0}
                Email:"${Data.Email || ""}"
              })
            }`,
                };
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: "CargarServi",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData).then((headers) => {
                    this.security.EncrypDataHash(DataAPI).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((Res) => {
                            //Respues del api al agregar el servicio en el sae 
                        });
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.UpgrateTurboClient);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                console.error(error);
            }
        }));
    }
    NewProspecto(Data, Mensaje) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query {
            ClientProspecto(
              token:"${environment.Token}"
              Data:{
                tipoCliente:"${Data.tipoCliente}"
                fullName:"${Data.cliente}"
                identidad:"${Data.identidad}"
                email:"${Data.email}"
                phone:"${Data.phone}"
                ciudad:"${Data.ciudad}"
                zona:"${Data.zona}"
                direccion:"${Data.direccion}"
                latitude:${Data.latitude}
                longitude:${Data.longitude}
              }
            Message:"${Mensaje}")
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.ClientProspecto);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    SolicitudPermisoTv(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          mutation{
            SolicitudPermisoTv(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            ){
              Permiso
              Publicidad
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.SolicitudPermisoTv);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    ControlPermisoTv(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query{
            PermisoTv(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            ){
              Permiso
              Publicidad
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.PermisoTv);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    DeleteDevice(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          mutation{
            DeleteDevice(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            )
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.DeleteDevice);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    VersionControl(Cedula, version) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ControlV`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Cedula,
                    version
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        console.log(data);
                        this.UrlUpdate = data.url;
                        resolve(data);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // Encabezados de la petición
                    const headersData = {
                        directory: "Consultas",
                        method: `MenuApp`,
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    yield this.security.EncrypDataHash(headersData)
                        .then((headers) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                            data = this.security.Descryp(data);
                            this.MenuApp = data;
                            resolve(data);
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    }))
                        .catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            }));
        });
    }
    ListService() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `InfoServices`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetIpDevice() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.http.get("https://api.ipify.org?format=json").subscribe((data) => {
                    resolve(data.ip);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    InsertChat(DataChat) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataMutaion = {
                    query: `
          mutation{
            CreateChat(
              Chat:{
                idEmpresa:"${DataChat.idEmpresa}"
                phone:"${DataChat.phone}"
                idConversacion:"${DataChat.idConversacion}"
                idmessage:"${DataChat.idmessage}"
                timestamp:${DataChat.timestamp}
                me_to:"${DataChat.me_to}"
                contentType:"${DataChat.contentType}"
                content:"${DataChat.content}"
                TimeRead:"${DataChat.TimeRead}"
                status:${DataChat.status}
                statusc:${DataChat.statusc}
                readmsg:${DataChat.readmsg}
                operador:"${DataChat.operador}"
                lic:"${DataChat.lic}"
                etiqueta:"${DataChat.etiqueta}"
              }
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              me_to
              contentType
              content
              TimeRead
              status
              statusc
              readmsg
              etiqueta
              operador
              lic
              tag
              DatePc
              priority
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataMutaion).subscribe((response) => {
                    resolve(response.data.CreateChat);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    ConsultaChat(Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query{
            ChatByPhoneApp(
              token:"${environment.Token}" 
              phone:"${Phone}"
              lic:"${environment.LicChat}"
            ){
                idEmpresa
                phone
                idConversacion
                idmessage
                timestamp
                me_to
                contentType
                content
                TimeRead
                status
                statusc
                readmsg
                operador
                lic
                etiqueta
                priority
                DatePc
            }
            }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.ChatByPhoneApp || []);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    SaveDataCLient(DataPost) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegsterClienteApp",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData).then((headers) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.security.EncrypDataHash(DataPost).then((DataBody) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response) => {
                            resolve(this.security.Descryp(response));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    })).catch((err) => console.error(err));
                })).catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetHistorialOrdenes(IdContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `HistorialOrdenes`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    idco: IdContrato,
                    id: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        let Historial = JSON.parse(data);
                        resolve(Historial.data.info);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    CorreoBienvenida(Email) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query{
            SendEmail(
              Correo:"${Email}"
              Titulo:"Bienvenido a la App de FIBEX TELECOM"
            ){
          to
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.SendEmail);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    sinCero(telefono) {
        return telefono.slice(0, 1) === "0" ? telefono.slice(1) : telefono;
    }
    sendMail(Email, titulo, contenido, telefono = "") {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query{
            SendEmail(
              Correo: "${Email}"
              Titulo: "${titulo}"
              Contenido: "${contenido}"
              Phone: "58${this.sinCero(telefono)}@c.us"
            ){
          to
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.SendEmail);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    ConsultaChat_Cola(Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          query{
            ChatCola(
              token:"${environment.Token}"
              Data:{
                phone:"${Phone}"
                lic:"${environment.LicChat}"
                status:0
              }
              Limit:10
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              contentType    
              content
              TimeRead
              operador
              lic
            }
          }`,
                };
                this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response) => {
                    resolve(response.data.ChatCola || []);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetServicesClientByIdContrato(IdContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServiciosIdCo`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: IdContrato,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        const Datafinal = JSON.parse(data);
                        console.log(Datafinal);
                        resolve(Datafinal.data.info);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    GetAllServiceCliente(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServiciosCe`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        const Datafinal = JSON.parse(data);
                        resolve(Datafinal.data.info);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    GetServicesDisponibles(IdServicio, IdContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServicesAvailable`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    idSer: IdServicio,
                    idCo: IdContrato,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    ClienteCRM(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.http.get(`${environment.apiFibexPlay}cedula=${Cedula}`).subscribe((data) => {
                resolve(data);
            }, (error) => {
                reject(error);
            });
        }));
    }
    ClienteSAE(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByDNI2`,
                token: environment.TokenUpdate,
                platform: "App",
                id: Cedula,
                lic: environment.Lic
            };
            this.security.EncrypDataHash(headersData)
                .then((headers) => {
                // Núcleo de la peticion
                //   console.log(environment.URLApi)
                this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                    console.log('DECRYPT >>>', this.security.Descryp(data));
                    resolve(this.security.Descryp(data));
                }, (error) => {
                    reject(error);
                });
            })
                .catch((err) => console.error(err));
        }));
    }
    IsClientFibex(cedula, email, telefono) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `IsValidClient`,
                token: environment.TokenUpdate,
                platform: "App",
                cedula: cedula,
                lic: environment.Lic
            };
            if (typeof email === "string")
                headersData.email = email;
            if (typeof telefono === "string")
                headersData.phone = telefono;
            this.security.EncrypDataHash(headersData)
                .then((headers) => {
                // Núcleo de la peticion
                //   console.log(environment.URLApi)
                this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                    console.log('DECRYPT >>>', this.security.Descryp(data));
                    resolve(this.security.Descryp(data));
                }, (error) => {
                    reject(error);
                });
            })
                .catch((err) => console.error(err));
        }));
    }
    emailSAE(email) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByEmail`,
                token: environment.TokenUpdate,
                platform: "App",
                id: email,
                lic: environment.Lic
            };
            this.security.EncrypDataHash(headersData)
                .then((headers) => {
                // Núcleo de la peticion
                //   console.log(environment.URLApi)
                this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                    resolve(this.security.Descryp(data));
                }, (error) => {
                    reject(error);
                });
            })
                .catch((err) => console.error(err));
        }));
    }
    FormatPhone(Phone) {
        const initPhone = Phone.substring(0, 3);
        if (Phone.charAt(0) === '0' && initPhone != '000') {
            const newphone = `58${Phone.substring(1, Phone.length)}@c.us`;
            return newphone;
        }
        else {
            if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
                return `58${Phone}@c.us`;
            }
            else if (initPhone != '000') {
                return `${Phone}@c.us`;
            }
        }
    }
    FormatPhoneSMS(Phone) {
        const initPhone = Phone.substring(0, 3);
        if (Phone.charAt(0) === '0' && initPhone != '000') {
            return Phone;
        }
        else if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
            return `0${Phone}`;
        }
    }
    HideLetterEmail(Email) {
        const Pos = Email.indexOf('@');
        if (Pos > 1) {
            let HideLetter = '', HideLetter2 = '';
            for (let index = 0; index < Pos - 6; index++) {
                HideLetter = HideLetter + '*';
            }
            for (let index = Pos + 1; index < Email.length - 1; index++) {
                HideLetter2 = HideLetter2 + '*';
            }
            return `${Email.substring(0, 3)}${HideLetter}${Email.substring(Pos - 3, Pos + 1)}${HideLetter2}`;
        }
        else {
            return Email;
        }
    }
    HideNumberPhone(Phone) {
        return `${Phone.substring(0, 4)} ***${Phone.substring(Phone.length - 4)}`;
    }
    LogChangeEmail(Data, OldEmail) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('tmEmailUpdates'),
                        'x-keys-to-add-id': '["IdChange"]',
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false',
                    })
                };
                const Body = {
                    IdChange: "",
                    Cedula: Data.identidad,
                    Name: Data.cliente,
                    Phone: Data.phone,
                    EmailOld: OldEmail || '',
                    EmailNew: Data.email
                };
                this.http.post(`${environment.ApiBackendThomas}create-info`, Body, httpOptions).subscribe((Res) => {
                    resolve(Res);
                }, error => {
                    reject(error);
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    DatosClientInstall(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('tmClientAppInstall'),
                        'x-keys-to-add-id': '["IdRegistro"]',
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false',
                    })
                };
                this.http.post(`${environment.ApiBackendThomas}create-info`, Data, httpOptions).subscribe((Res) => {
                    resolve(Res);
                }, error => {
                    reject(error);
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    SendComandContr(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const DataQuery = {
                    query: `
          mutation {
            ControlRemoto(Data:{
              Codigo:"${Data.Codigo}"
              Cedula:"${Data.Cedula}"
              Url:"${Data.Url}"
              Comando: "${Data.Comando}"
              Timer: "${Data.Timer}"
            })
          }`,
                };
                this.http.post(environment.ApiBackendFp, DataQuery).subscribe((response) => {
                    resolve(response.data.ControlRemoto);
                }, (error) => {
                    this._logs.insertarError(error.message);
                    reject(error);
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    RegisterDeviceFibexPLay(Cedula, Code) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const Data = {
                    "Cedula": Cedula.replace('_C', ''),
                    "Code_Device": Code.replace('_C', '')
                };
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegisterDeviceFP",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    this.security.EncrypDataHash(Data).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((responde) => {
                            resolve(this.security.Descryp(responde));
                        }, (err) => {
                            reject(err);
                        });
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    InsertViewControlCobranza(Data) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegisterControlCb",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    this.security.EncrypDataHash(Data).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResInsert) => {
                            resolve(this.security.Descryp(ResInsert));
                        }, (error) => {
                            reject(error);
                        });
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    SendSMS(Phone, Text) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const Data = {
                    "to": this.FormatPhoneSMS(Phone),
                    "Message": Text
                };
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "SendSms",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    this.security.EncrypDataHash(Data).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResSMS) => {
                            resolve(this.security.Descryp(ResSMS));
                        }, (error) => {
                            reject(error);
                        });
                    }).catch((err) => console.error(err));
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    SendKitBienvenida(CedulaClient, TipoCliente, CedulaTecnico, SecondPhone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `SendWelcomeKit`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    TipoCliente,
                    CedulaClient,
                    CedulaTecnico,
                    SecondPhone: SecondPhone || null,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(Res => {
                        //repuesta
                    }, (error) => {
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    SendMsgwhatsapp(Datos) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "IntermediaryW",
                    token: environment.TokenUpdate,
                    platform: "App",
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    this.security.EncrypDataHash(Datos).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response) => {
                            resolve(this.security.Descryp(response));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    })
                        .catch((err) => console.error(err));
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    GeneratePin(Cedula, tipo) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `GenerarPin`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    tipo: tipo
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(Res => {
                        resolve(this.security.Descryp(Res));
                    }, (error) => {
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    GeneratePinv2(Cedula, tipo, plataform) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `GenerarPin`,
                    token: environment.TokenUpdate,
                    platform: plataform,
                    id: Cedula,
                    tipo: tipo
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(Res => {
                        resolve(this.security.Descryp(Res));
                    }, (error) => {
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    VerificarPin(Cedula, Pin) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `VerificarPin`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    pin: Pin
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(Res => {
                        resolve(this.security.Descryp(Res));
                    }, (error) => {
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    getBranchOffices() {
        return new Promise((resolve, reject) => {
            const qHeaders = {
                directory: "Consultas",
                method: `SucursalesAPP`,
                token: environment.TokenUpdate
            };
            try {
                this.security.EncrypDataHash(qHeaders).then((headers) => {
                    this.http.get(`${environment.URLApi}`, { headers }).subscribe(response => {
                        resolve(this.security.Descryp(response));
                    }, (error => reject(error)));
                }).catch(error => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    getFAQ() {
        return new Promise((resolve, reject) => {
            const qHeaders = {
                directory: "Consultas",
                method: `FAQAPP`,
                token: environment.TokenUpdate
            };
            try {
                this.security.EncrypDataHash(qHeaders).then((headers) => {
                    this.http.get(`${environment.URLApi}`, { headers }).subscribe(response => {
                        resolve(this.security.Descryp(response));
                    }, (error => reject(error)));
                }).catch(error => reject(error));
            }
            catch (error) {
                reject(error);
            }
        });
    }
    GetSSID(Serial) {
        return new Promise((resolve, reject) => {
            try {
                const headers = {
                    TokenAuth: environment.TokenZella,
                    Authorization: environment.authZella,
                    Serial: Serial
                };
                this.http.get(`${environment.urlZella}ViewSSID`, { headers }).subscribe(res => {
                    resolve(res);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    ChangeWifiPass(Data) {
        //Esta es la data
        // interface BodyChangeWifi {
        //   Abonado: string,
        //   Cedula: string,
        //   SSID: string,
        //   Password: string,
        //   Serial: string,
        // }
        return new Promise((resolve, reject) => {
            try {
                const headers = {
                    TokenAuth: environment.TokenZella,
                    Authorization: environment.authZella
                };
                this.http.post(`${environment.urlZella}ViewSSID`, Data, { headers }).subscribe(res => {
                    resolve(res);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    GetPlanes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const headers = {
                    method: this.enc.encrypt(`PlanesDic`),
                    token: this.enc.encrypt(environment.TokenUpdate),
                    platform: this.enc.encrypt("App")
                };
                const result = yield axios.get(`${environment.URLApi}Consultas`, { headers });
                return result.data;
            }
            catch (error) {
                return null;
                console.log('error', error);
            }
        });
    }
    /*async getNotify(id_cliente: string) {
      try {
        const headersData = {
          db: this.enc.encrypt(environment.NameBD),
          table: this.enc.encrypt(environment.tableNotify),
          type: this.enc.encrypt('find-any-info'),
          campo: this.enc.encrypt('id_cliente'),
          valor: this.enc.encrypt(id_cliente),
          TokenAuthPlataform: environment.TokenBackendFull,
          Authorization: environment.authdbFUll
        };
        this.notifications = await this.http.get(`${environment.ApiBackendThomas}`,{ headers: headersData }).toPromise();
        return {
          error: false,
          data: this.notifications
        }
      } catch (error) {
        this._logs.insertarError(error.message);
        return {
          error: true,
          data: []
        }
      }
    }*/
    getNotify(id_cliente, readed, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    console.log("CONSULTANDO NOTIFICACIONES AQUI >>>>>>>>>>>>>>>>>>>>>>>>>>>>");
                    environment.tableNotify;
                    // const headersData = {
                    //     db: this.enc.encrypt(environment.NameBDV2),
                    //     table: this.enc.encrypt(environment.tableNotify),
                    //     type: this.enc.encrypt('find-any-info'),
                    //     campo: this.enc.encrypt('id_cliente'),
                    //     campo2: this.enc.encrypt("read_at"),
                    //     valor: this.enc.encrypt(id_cliente),
                    //     valor2: this.enc.encrypt("null"),
                    //     TokenAuthPlataform: environment.TokenBackendFull,
                    //     Authorization: environment.authdbFUll
                    // };
                    // this.http.get(`${environment.ApiBackendThomas}`, { headers: headersData }).subscribe(data => {
                    //     console.log(data);
                    //     resolve(data)
                    // });
                    this.dbfull2.GET_ANY_QUERY({ query: `
                    SELECT * from ${environment.tableNotify} 
                    where id_cliente = '${id_cliente}' and 
                    ${readed ? 'read_at is not NULL' : 'read_at is NULL'}
                    limit 10 offset ${(page || 0) * 10}` })
                        .then(res => {
                        console.log('HOLA MUNDO', res);
                        resolve(res);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            });
        });
    }
    setNotify(data, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let headers = {
                        'db': this.enc.encrypt(environment.NameBDV2),
                        'table': this.enc.encrypt(environment.tableNotify),
                        'campo': this.enc.encrypt('id'),
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'Authorization': environment.authdbFUll,
                        'Content-Type': 'application/json',
                        'TokenAuthPlataform': environment.TokenBackendFull
                    }, body = {
                        'id': data.id,
                        'read_at': date
                    };
                    axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response) => {
                        if (response.status == 200) {
                            this.EmiterNotify(true);
                        }
                        resolve(response);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                    return {
                        error: true,
                        data: []
                    };
                }
            });
        });
    }
    GetBannerUpgradeOficinaMovil() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const headersData = {
                    db: this.enc.encrypt(environment.NameBD),
                    table: this.enc.encrypt(environment.tableBanner),
                    type: this.enc.encrypt('find-any-info'),
                    campo: this.enc.encrypt('type'),
                    valor: this.enc.encrypt('popUpgrade'),
                    TokenAuthPlataform: environment.TokenBackendFull,
                    Authorization: environment.authdbFUll
                };
                const result = yield axios.get(`${environment.ApiBackendThomas}`, {
                    headers: headersData
                });
                return result.data;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    deleteNotify(data, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let headers = {
                        'db': this.enc.encrypt(environment.NameBDV2),
                        'table': this.enc.encrypt(environment.tableNotify),
                        'campo': this.enc.encrypt('id'),
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'Authorization': environment.authdbFUll,
                        'Content-Type': 'application/json',
                        'TokenAuthPlataform': environment.TokenBackendFull
                    }, body = {
                        'id': data.id,
                        'deleteAt': date
                    };
                    axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response) => {
                        if (response.status == 200) {
                            this.EmiterNotify(true);
                        }
                        resolve(response);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                    return {
                        error: true,
                        data: []
                    };
                }
            });
        });
    }
    getpaymentID(id_contrato, date) {
        return new Promise((resolve, reject) => {
            try {
                this.dbfull.GET_ANY_QUERY({ query: `SELECT id_pago FROM cobranza.pagos where id_contrato = '${id_contrato}' and status_pago = 'GENERADA' and fecha_factura = '${date}'` })
                    .then(res => {
                    resolve(res);
                });
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
    updateScore(subscriber, id_contrato, id_pago) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(subscriber);
            const body = {
                id_contrato: this.enc.encrypt(id_contrato),
                id_pago: this.enc.encrypt(id_pago),
            };
            console.log(body);
            return new Promise((resolve, reject) => {
                try {
                    let headers = {
                        'db': this.enc.encrypt(environment.NameBD),
                        'table': this.enc.encrypt('tmPuntosClientes'),
                        'campo': this.enc.encrypt('abonado'),
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'Authorization': environment.authdbFUll,
                        'Content-Type': 'application/json',
                        'TokenAuthPlataform': environment.TokenBackendFull
                    }, body = {
                        'abonado': subscriber,
                        'puntos': 0
                    };
                    console.log(headers);
                    console.log(body);
                    axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response) => {
                        resolve(response.status);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    console.error(error);
                    reject(error);
                    return {
                        error: true,
                        data: []
                    };
                }
            });
        });
    }
    generateCreditNote(contractID, paymentID, mount) {
        console.log(contractID);
        console.log(paymentID);
        console.log(mount);
        const headers = {
            'method': this.enc.encrypt('SolicitudNotaCredito'),
            'directory': this.enc.encrypt('SAE'),
            'token': environment.tokenTLS,
            'platform': this.enc.encrypt('app')
        };
        const body = {
            'Lic': this.enc.encrypt(environment.Lic),
            'id_contrato': this.enc.encrypt(contractID),
            'id_pago': this.enc.encrypt(paymentID),
            'monto': mount,
            'detalle_nota': this.enc.encrypt('CANJEO DE RECOMIENDA Y GANA')
        };
        return new Promise((resolve, reject) => {
            try {
                axios.post(environment.URLApi, body, { headers: headers })
                    .then(res => {
                    const result = res.data;
                    const decrypt = this.enc.Descryp(result);
                    console.log(decrypt);
                    resolve(decrypt.data.info);
                })
                    .catch(err => {
                    reject({ success: false });
                    console.error(err);
                });
            }
            catch (error) {
                reject({ success: false });
                console.error(error);
            }
        });
    }
    checkLastUse(ci) {
        const headers = {
            ci,
            Authorization: 'Basic ' + btoa(`${environment.hash_user}:${environment.hash_password}`),
            TokenAuth: environment.hash_token
        };
        return new Promise((resolve, reject) => {
            try {
                axios.get(`${environment.hash_url}lastUseApp`, { headers })
                    .then(res => resolve(res.data))
                    .catch(error => {
                    console.error(error);
                    reject(error);
                    throw new Error();
                });
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
    getSaldoBCV() {
        return this.http.get(`https://service.apibcv.net/SearchBCV/ertyhbnjklo89dvty782`, { headers: { 'Content-Type': 'application/json' } }).pipe(map((res) => {
            // console.log('res', res)
            return res.filter((coin) => coin.moneda == "USD")[0].precio;
        }));
    }
    createLogSoporteApp(data) {
        return new Promise((resolve, reject) => {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('tmSoporteApp'),
                        'x-keys-to-add-id': '[]',
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false'
                    })
                };
                this.http.post(`${environment.ApiBackendThomas}create-info`, data, httpOptions).subscribe(res => {
                    resolve(res);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        });
    }
    authUserCredentials({ dni, usermail, userphone, ask, answer }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'Login'
                    };
                    const data = Object.assign({ dni,
                        usermail,
                        userphone }, (ask && answer ? {
                        ask,
                        answer
                    } : {}));
                    this.security.EncrypDataHash(qHeaders).then((headers) => {
                        this.security.EncrypDataHash(data).then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                resolve(this.security.Descryp(data));
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            }));
        });
    }
    getSecurityQuestions(dni) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("GETTING SECURITY QUESTIONS!!!");
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'getSecurityQuestions',
                        cedula: dni
                    };
                    const data = {
                    // dni,
                    // usermail,
                    // userphone
                    };
                    console.log("ARE YOU DOING SECURITY");
                    this.security.EncrypDataHash(qHeaders).then((headers) => {
                        this.security.EncrypDataHash(data).then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                console.log("RESPONSE SECURITY QUESTIONS");
                                resolve(this.security.Descryp(data));
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            }));
        });
    }
    logoutSession(token) {
        return new Promise((resolve, reject) => {
            try {
                if (token) {
                    console.log("HERE MAN");
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'Logout'
                    };
                    const qData = {
                        auth: token
                    };
                    this.security.EncrypDataHash(qHeaders)
                        .then((headers) => {
                        this.security.EncrypDataHash(qData)
                            .then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                resolve(data);
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        })
                            .catch(reject);
                    })
                        .catch(reject);
                }
                else {
                    reject(new Error("Token de authenticacion requerido"));
                }
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        });
    }
    Logout() {
        // this.push.stopConnection();
        const auth = this._localStorageService.get("auth");
        // this._localStorageService.remove('auth-user');
        // this.authentication.logout();
        //Borro el serial de su equipo pq si inicia con otra cuenta esto debe ser reseteado
        this.Serial = "";
        // console.log(this.appPages);
        //this._routing.ChangeRuta('login');
        // this._routing.ChangeRuta("");
        this.menu.close();
        if (environment.kioskoApp) {
            this._localStorageService.removeAll();
        }
        this.logoutEmitter.emit();
        this.logoutSession(auth)
            .then(console.log)
            .catch(console.error)
            .finally(() => {
            console.log(">>>>>>>>>>>>>>> END");
            localStorage.clear();
            this._localStorageService.remove('fav_products');
            this.onesignal.emitSign();
            this.cache.clear();
            this.navCtrl.navigateRoot(["new-login"], { replaceUrl: true });
        });
    }
    GetDocumentoFactura(TipoFactura, NroFactura) {
        return new Promise((resolve, reject) => {
            try {
                axios.post("https://facturadig.thomas-talk.me/Envio", {
                    TipoFactura: TipoFactura,
                    NroFactura: NroFactura
                }, {
                    headers: {
                        method: this.security.encrypt("ConsultarDocumento"),
                        token: this.security.encrypt("4p1_[51t3m4s]_f186@th0m4s"),
                    }
                })
                    .then((response) => resolve(response.data))
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    }
}
ConsultasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsultasService_Factory() { return new ConsultasService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SeguridadDatos), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i2.SeguridadDatos), i0.ɵɵinject(i4.MiEquipoService), i0.ɵɵinject(i5.LocalStorageService), i0.ɵɵinject(i6.MenuController), i0.ɵɵinject(i7.OneSignalService), i0.ɵɵinject(i8.ClearCacheService), i0.ɵɵinject(i6.NavController)); }, token: ConsultasService, providedIn: "root" });

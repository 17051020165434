import * as tslib_1 from "tslib";
import axios from "axios";
import { environment } from '../../environments/environment';
import { CompanyInterface, GetCategoriesInteface, ProductInterface, BannerInterface, CouponProductInterface } from '../interfaces/product.interface';
import { LocalStorageService } from './local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { dbFullDataBase } from "../lib/dbfullv2";
import { SeguridadDatos } from "./bscript.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./user-authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "./bscript.service";
export class ClubFibexFeedingService {
    constructor(http, localStorageService, AuthService, router, enc) {
        this.http = http;
        this.localStorageService = localStorageService;
        this.AuthService = AuthService;
        this.router = router;
        this.enc = enc;
        this.ShowInfoTable = "";
        this.Excluido = ["createdAt", "updatedAt"];
        this.favProducts = new BehaviorSubject([]);
        this.goToMainMenuSubject = new BehaviorSubject(null);
        this.popupIndexCount = 0;
        /**
         * Agrega más videos popup si la longitud es menor a 31
         */
        this.addPopUpVideo = (popupVideos) => {
            if (popupVideos.length <= 31 && this.popupIndexCount <= 31) {
                popupVideos.push(popupVideos[this.popupIndexCount]);
                this.popupIndexCount++;
                this.addPopUpVideo(popupVideos);
            }
        };
        this.processQREmpresa = (QRcode, idEmpConv, CodigoEmpresa) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const body = {
                    QRcode,
                    idEmpConv,
                    CodigoEmpresa
                };
                console.log('body', body);
                const result = yield axios.post(`${environment.apiTest}/ExchangeTienda`, body);
                console.log('RESULT SCAN >>>', result.data);
                if (result.data["message"] && result.data["message"] === "QR Aliado Invalido") {
                    throw new Error(result.data["message"]);
                }
                if (!result.data['status']) {
                    throw new Error(result.data['error']);
                }
                return result.data;
            }
            catch (error) {
                console.error(error);
                return Promise.reject({ error: error.name });
            }
        });
        this.getCouponByID = (idCupon) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_ControlCupon ccc WHERE ccc.idControlCupon="${idCupon}"`
                    }
                });
                return result.data[0];
            }
            catch (error) {
                return null;
            }
        });
        this.getDaysCoupon = (QRcode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const dbCon = new dbFullDataBase(environment.clubFibexBD);
                const response = yield dbCon.GET_ANY_QUERY({ query: `SELECT ccc.FechaGenerado, cpp.FechaLimite FROM cb_ControlCupon ccc 
            INNER JOIN cb_PromoProductos cpp ON(ccc.idPromo = cpp.idPromo)
            WHERE ccc.QR ="${QRcode}" AND ccc.Status = 1 AND ccc.Canjeado =0` });
                if (Array.isArray(response) && response.length > 0) {
                    const data = response[0];
                    return Promise.resolve({ status: true, FechaGenerado: data.FechaGenerado, FechaLimite: data.FechaLimite });
                }
                else {
                    return Promise.reject({ status: false, message: "El cupon no se encuentra dentro de la promocion,  esta inactivo  o ha sido canjeado" });
                }
            }
            catch (error) {
                console.error(error);
                return Promise.reject(null);
            }
        });
        this.getRankingPromos = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.apiTest}/getrankingcoupons`);
                const responseData = result.data;
                console.log(responseData.data);
                if (responseData.status && Array.isArray(responseData.data) && responseData.data.length > 0) {
                    return Promise.resolve(responseData.data);
                }
                throw new Error("Error en obtener el ranking");
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getRecentPromos = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.apiTest}/getrecentpromos`);
                const responseData = result.data;
                console.log(responseData.data);
                if (responseData.status && Array.isArray(responseData.data) && responseData.data.length > 0) {
                    return Promise.resolve(responseData.data);
                }
                throw new Error("Error en obtener el ranking");
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getFeaturedPromos = (idAbonado) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL promosFeatured('${idAbonado}');`
                    }
                });
                console.log(result.data);
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getFeaturedAllies = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompaniesFeatured`
                    }
                });
                return result.data;
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getSucursales = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.AviableCities`
                }
            });
            return result.data;
        });
        this.user = AuthService.getUser();
    }
    ngOnInit() {
        //this.ShowInfo();
    }
    GetCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_EmpCategoria/Status/1`);
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp`
                    }
                });
                //   console.log('result',result)
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    /**
     * Permite obtener la cantidad total de compañias
     */
    GetCountCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                        "x-data-query": `SELECT count(*) as count FROM ${environment.clubFibexBD}.CompanyApp`
                    }
                });
                //   console.log('result',result)
                return result.data[0].count || 0;
            }
            catch (error) {
                return 0;
            }
        });
    }
    /**
     * Permite obtener de manera paginada las compañias o marcas aliadas
     * @param from Indice al buscar
     * @param limit Cantidad de elementos a obtener a partir del indice establecido
     * @returns Lista de datos de las compañias
     */
    GetCompaniesSliced(from, limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        // "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE idEnSAE = "${idFranquiciaUser}";`
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp limit ${limit} offset ${from}`
                    }
                });
                //   console.log('result',result)
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompany(id_company) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE id = "${id_company}"`
                    }
                });
                //   console.log(result.data[0])
                return result.data[0];
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompaniesSelect(limit, offset, arrayCitiesId, arrayCategories, searchData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let currentOff = (offset - 1) * limit;
                const search = searchData ? searchData : "";
                const citiesId = arrayCitiesId && arrayCitiesId.length > 0 ? `"${arrayCitiesId.map(idCitie => `${idCitie}`).join(",")}"` : `""`;
                const categories = arrayCategories && arrayCategories.length > 0 ? `"${arrayCategories.map(idCategory => `${idCategory}`).join(",")}"` : `""`;
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL companiesByCities(${currentOff},${limit},${citiesId},${categories},"${search}")`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    SearchCompaniesSelect(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT id,name FROM ${environment.clubFibexBD}.CompanyApp WHERE name LIKE("%${name}%")`
                    }
                });
                //   console.log(result.data[0])
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    SearchCompaniesById(ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log(ids);
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT ca.id,ca.name FROM ${environment.clubFibexBD}.CompanyApp ca WHERE ca.id IN(${ids})`
                    }
                });
                //   console.log(result.data[0])
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetProducts(idFranquiciaUser, nro_contrato) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('idFranquiciaUser', idFranquiciaUser)
            // console.log('contrato', nro_contrato)
            try {
                console.log('headers', {
                    "x-data-query": `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`
                });
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`
                    }
                });
                console.log(`CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`);
                //   console.log('result.data', result.data)
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetFranqByCurrentLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const currentLocation = yield this.GetLocation();
                // console.log('currentLocation', currentLocation)
                if (currentLocation && currentLocation.address.city) {
                    const userCity = `FIBEX ${currentLocation.address.city.toUpperCase()}`;
                    const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                        headers: {
                            "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Nombre = "${userCity}";`
                        }
                    });
                    return result.data;
                }
                return null;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetBanners(idFranquiciaUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic`
                    }
                });
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetFranquicia(idFranquiciaUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE idFranquicia = "${idFranquiciaUser}";`
                    }
                });
                // const franqName: unknown = result.data
                // console.log("franqName",franqName.Nombre.replace('FIBEX ', ''))
                // console.log(result.data)
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetAllFranquicias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_Franquicia/Status/1`);
                // console.log('all franquicias', result.data)
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetProductBranchOffices(productID, franchiseID, idPromo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log(productID);
                console.log(franchiseID);
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL ProductoSucursales('${productID}','${franchiseID}','${idPromo}');`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetAllData(table) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                axios.get(`${environment.UrlFull}api/v1/find-all-info/${environment.bd}/${table}`)
                    .then((resp) => resolve(resp.data))
                    .catch((error) => reject(error));
            }));
        });
    }
    GetAllDataCampo(table, campo, valor) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
                    .then((resp) => resolve(resp.data))
                    .catch((error) => reject(error));
            }));
        });
    }
    CreateData(table, idkey, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let config = {
                    method: 'post',
                    url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${table}`,
                    headers: {
                        'x-keys-to-add-id': `["${idkey}"]`,
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false',
                        'Content-Type': 'application/json'
                    },
                    data: body
                };
                axios.request(config)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            }));
        });
    }
    UpdateData(table, campoSearch, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let config = {
                    method: 'put',
                    url: `${environment.UrlFull}api/v1/update-info/${environment.bd}/${table}/${campoSearch}`,
                    headers: {
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'campo': '',
                        'Content-Type': 'application/json'
                    },
                    data: body
                };
                axios.request(config)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            }));
        });
    }
    ShowInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.ShowInfoTable && this.ShowInfoTable != "") {
                    resolve(this.ShowInfoTable);
                }
                else {
                    let config = {
                        method: 'get',
                        url: `${environment.UrlFull}api/v1/show-all-info/${environment.bd}`,
                    };
                    axios.request(config)
                        .then((response) => { resolve(response.data); this.ShowInfoTable = response.data; })
                        .catch((error) => reject(error));
                }
            }));
        });
    }
    CamposTableReturn(table) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (table && table != "") {
                    if (this.ShowInfoTable && this.ShowInfoTable != "") {
                        let info = this.ShowInfoTable.model.filter((datadb) => (datadb.tableName === table));
                        if (info && info.length > 0) {
                            info = info[0].attr.filter((camposexluido) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
                            resolve(info);
                        }
                        else
                            resolve(info);
                    }
                    else {
                        this.ShowInfo()
                            .then((resp) => {
                            this.ShowInfoTable = resp;
                            let info = this.ShowInfoTable.model.filter((datadb) => (datadb.tableName === table));
                            if (info && info.length > 0) {
                                console.log("info");
                                console.log(info[0].attr);
                                info = info[0].attr.filter((camposexluido) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
                                resolve(info);
                            }
                            else
                                resolve(info);
                        })
                            .catch((error) => { reject({ error: error }); });
                    }
                }
                else {
                    reject({ error: "Error debes poner un nombre de tabla" });
                }
            }));
        });
    }
    Test() {
        /*Test1 por campos
        this._dbFullService.GetAllDataCampo('cb_EmpCategoria','nombre','Comida')
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/
        /*
        Test2
        let Data ={
          idEmpConv:"100",
          idFranquicia:"GG5HP",
          Nombre: "Damasco",
          Status:true,
          ImgURL: "https://usatoday.com"
        }
     
        this._dbFullService.CreateData('cb_EmpresaSucursal','idSucursal',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/
        /*
        Test3
        let Data ={
          idEmpCat:"123458",
          nombre: "Comida",
          icon:"fa fa-burguer",
          img: "http://scribd.com2"
        }
     
        this._dbFullService.UpdateData('cb_EmpCategoria','idEmpCat',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })
        */
    }
    handleProductFav(product) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const user = this.AuthService.getUser();
                // const storageData = this.localStorageService.get('cedulas');
                const { nro_contrato } = user;
                // const { idProducto } = product;
                const { id } = product;
                const body = {
                    idAbonado: nro_contrato,
                    idProducto: id,
                };
                product.fav = !product.fav;
                const result = yield axios.post(`${environment.apiTest}/${environment.favMethod}`, body);
                console.log('result', result);
                // if (result && result.status) {
                //     /*
                //     !!Almacenamiento en el local Storage
                //     let favProductsStorage: ProductInterface[] = this.localStorageService.get('fav_products') || []
                //     console.log("favProductsStorage", favProductsStorage);
                //      if (product.fav) {//!Elimino item del localStorage
                //       favProductsStorage.push(product)
                //       console.log('Item Guardado', favProductsStorage)
                //       this.localStorageService.set('fav_products', favProductsStorage)
                //     } else {//Lo agrego al local storage
                //       let index = favProductsStorage.findIndex((productStorage:ProductInterface) => product.id === productStorage.id);
                //       favProductsStorage.splice(index,index+1)
                //       this.localStorageService.set('fav_products', favProductsStorage)
                //     }
                //       //!Actualizo el producto de la base de datos
                //       if (favProductsStorage && favProductsStorage.length) {
                //         favProductsStorage.map(async productStorage => {
                //           const ProductDB = await this.filterProductById(productStorage.id, nro_contrato)
                //           console.log("ProductDB;",ProductDB);
                //           productStorage = ProductDB;
                //         })
                //       }
                //       console.log("favProductsStorage", favProductsStorage);
                //       */
                // }
                // console.log("FavResult", result.data)
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    filterProductById(productId, nro_contrato) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let products = yield this.GetProducts(this.user.id_franq, nro_contrato);
                return products.find(product => productId === product.id);
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCoupons(idAbonado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log("idAbonado", idAbonado);
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL Cupones("${idAbonado}");`
                        // "x-data-query": `CALL Cupones("C0824");`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                return null;
            }
        });
    }
    postRating(rating) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const body = rating;
                const result = yield axios.post(`${environment.apiTest}` + '/ReportCuponsGenerated', body);
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    ProductoSucursales(productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("productId", productId);
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL ProductoSucursales("${productId}");`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                return null;
            }
        });
    }
    GetFavProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // console.log("GetFavProducts");
                let productsFav = yield this.GetProducts(this.user.id_franq, this.user.nro_contrato);
                productsFav = productsFav.filter(product => product.fav);
                this.favProducts.next(productsFav);
                return this.favProducts.asObservable();
            }
            catch (error) {
                return null;
            }
        });
    }
    GetQR(product, branchOfficeID = 'asdsd5') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log(branchOfficeID)
            try {
                const { id, company, idAbonado, TipoCanjeo, CodigoFijo } = product;
                const body = {
                    idEmpConv: company,
                    // idSucursal: 'asdsd5',
                    idSucursal: branchOfficeID,
                    idAbonado: idAbonado,
                    idPromo: id,
                    TipoCanjeo: TipoCanjeo,
                    CodigoFijo: CodigoFijo
                };
                // console.log('BODY BEFORE GENERATE QR')
                // console.log(body)
                const result = yield axios.post(`${environment.apiTest}/${environment.qrMethod}`, body);
                // console.log("result", result);
                return result.data;
            }
            catch (error) {
                // console.log('errorrrrr')
                console.error(error);
                return null;
            }
        });
    }
    goToMainMenuEvent() {
        // console.log('next')
        this.goToMainMenuSubject.next();
        return this.goToMainMenuSubject.asObservable();
    }
    GetLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (navigator.geolocation) { //check if geolocation is available
                return new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition((position) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        const result = yield axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`);
                        // console.log('location', result.data)
                        resolve(result.data);
                    }), (error) => reject(error));
                });
            }
        });
    }
    getAdvertisingBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_AdvertisingBanner WHERE status = 1;`
                    }
                });
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    getMarksBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic WHERE status = 1;`
                    }
                });
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    generateQRBill({ NombreProducto, description, idAbonado, price, Codigo, Cupon }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const current = new Date();
            const body = {
                date: current.toLocaleDateString('en-GB'),
                time: current.toLocaleTimeString('en-US'),
                promoTitle: NombreProducto,
                subscriber: idAbonado,
                price,
                promoDescription: description,
                qrUrl: Cupon,
                qrCode: Codigo,
            };
            try {
                const result = yield axios.post('https://apitest5.thomas-talk.me/generate-qr-bill', body);
                // const result = await axios.post('http://localhost:8200/generate-qr-bill', body)
                return result.data;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    getPopUpClubFibex() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let popUpVideos = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'popUp' AND status = 1;`
                    }
                });
                this.addPopUpVideo(popUpVideos.data);
                return popUpVideos.data[new Date().getDate()].imgDesktop;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    getRecommendAndWinAd() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log(`SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`);
                console.log(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`);
                const response = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`
                    }
                });
                return response.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    getRecommendAndWinGif() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWinGif' AND status = 1;`
                    }
                });
                return response.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    //METODOS CONSULTAS PAGINADOS
    //STORED PROCEDURES
    getCouponsPaginated(idAbonado, currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let totalPages = 0;
                let offset = (currentPage - 1) * limitPage;
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL Cupones_pag("${idAbonado}",${offset},${limitPage});`
                        // "x-data-query": `CALL Cupones("C0824");`
                    }
                });
                console.log('', "RESULTADO: ", result);
                return Object.values(result.data[0]);
            }
            catch (error) {
                return null;
            }
        });
    }
    getProductsPaginated(idFranquiciaUser, nro_contrato, currentPage, limitPage, categories, companies, pricing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let totalPages = 0;
                console.log(pricing);
                let offset = (currentPage - 1) * limitPage;
                console.log('', 'offset', offset);
                const data = categories ? `'${categories}'` : 'NULL';
                const dataCompany = companies ? `'${companies}'` : 'NULL';
                const dataPricing = pricing && pricing.length == 2 ? `${pricing[0]},${pricing[1]}` : `NULL,NULL`;
                console.log('', 'entre a getProductsPaginated');
                console.log({
                    idFranquiciaUser,
                    nro_contrato,
                    currentPage,
                    limitPage,
                    data,
                    dataCompany,
                    dataPricing
                });
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL getProductsFiltered('${idFranquiciaUser}','${nro_contrato}',${offset},${limitPage},${data},${dataCompany},${dataPricing});`
                    }
                });
                console.log('', 'result', result);
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    getProductsSucPaginated(productId, currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let totalPages = 0;
                let offset = (currentPage - 1) * limitPage;
                console.log("productoSsucursales_pag");
                console.log("productId");
                console.log(productId);
                console.log("offset");
                console.log(offset);
                console.log("limitPage");
                console.log(limitPage);
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL productoSsucursales_pag('${productId}',${offset},${limitPage});`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                return null;
            }
        });
    }
    //VISTAS Y TABLAS
    GetAllDataPaginated(table, currentPage, limitPage, dataCampo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let conditionalsConcatenated = "";
                let offset = (currentPage - 1) * limitPage;
                if (dataCampo) {
                    conditionalsConcatenated += "WHERE ";
                    conditionalsConcatenated += dataCampo.map(obj => {
                        let valor = typeof (obj.valor) == 'string' ? `'${obj.valor}'` : `${obj.valor}`;
                        return `${obj.campo} =  ${valor}`;
                    }).join("AND");
                }
                try {
                    const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                        headers: {
                            "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.${table} ${conditionalsConcatenated} LIMIT ${limitPage} OFFSET ${offset}`
                        }
                    });
                    resolve(result.data);
                }
                catch (error) {
                    return null;
                }
            }));
        });
    }
    getCompaniesPaginated(currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.GetAllDataPaginated("CompanyApp", currentPage, limitPage);
                console.log('', 'result', result);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    getBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            {
                try {
                    const result = yield this.GetAllDataPaginated("cb_BannerPublic", 1, 5, [{ campo: "Status", valor: "1" }]);
                    return result;
                }
                catch (error) {
                }
            }
        });
    }
    searchProductsByName(idAbonado, dataSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //  alert("enviando: "+dataSearch);
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `CALL searchProducts("${idAbonado}","${dataSearch}")`
                    }
                });
                return Object.values(result.data[0]);
            }
            catch (error) {
                console.error(error);
                return [];
            }
        });
    }
    getAdvertisingBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.GetAllDataPaginated("cb_AdvertisingBanner", 1, 5, [{ campo: "Status", valor: "1" }]);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    //7
    getMarksBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //cb_BannerPublic
            try {
                const result = yield this.GetAllDataPaginated("cb_BannerPublic", 1, 5, [{ campo: "Status", valor: "1" }]);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    gettPopUpsOM() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='popUpOM' AND status = 1;`
                    }
                });
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    gettVideoOM() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                    headers: {
                        "x-data-query": `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='videoBanner' AND status = 1;`
                    }
                });
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    getContactDateByEnterprise(idEmpConv) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(idEmpConv);
            const result = yield axios.get(`${environment.UrlFull}api/v1/any-queries/${environment.clubFibexBD}`, {
                headers: {
                    "x-data-query": `SELECT ccp.Telefono,ccp.Contacto FROM cb_ContactoPrincipal ccp WHERE ccp.idEmpConv = '${idEmpConv}'`
                }
            });
            console.log(result.data);
            return result.data[0];
        });
    }
}
ClubFibexFeedingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClubFibexFeedingService_Factory() { return new ClubFibexFeedingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.UserAuthenticationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.SeguridadDatos)); }, token: ClubFibexFeedingService, providedIn: "root" });

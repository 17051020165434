import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



// ! AVISO IMPORTANTE!!!! NO ES NECESARIO UTILIZAR LAS SIGUIENTES LINEAS DE CODIGO
// !
// ! console.log = () => { }
// ! console.error = () => { }
// ! console.warn = () => { }
// !
// ! YA QUE ESTO YA SE ESTÁ HACIENDO EN EL SERVICIO EN:  app/services/log/CapturarRegistrarError.ts
// ! POR LO QUE CUALQUIER PROBLEMA RELACIONADO A QUE NO SE MUESTRAN EL CONSOLE.LOG() PODRÍA ESTAR RELACIONADO A ESO


if (environment.production) {
  enableProdMode();
  // console.log = () => { }
  // console.error = () => { }
  // console.warn = () => { }
}

// CANCELA TODOS LOS MENSAJES DE LA CONSOLA
  console.log = () => { }
  console.error = () => { }
  console.warn = () => { }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

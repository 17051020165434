import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo, UserInterface } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';

export interface IUserSae {
  apellido: string,
  c_sistema: boolean,
  calificacion: null,
  campo1: string,
  campo2: string,
  campo3: string,
  campo4: string,
  campo5: string,
  cedula: string,
  cli_id_persona: string,
  cliente: string, // Nombre completo
  cod_id_persona: string,
  codigo_franq: string,
  codigo_legal: string,
  contrato_fisico: string,
  contrato_imp: string,
  costo_dif_men: string,
  direccion_franq: string,
  email: string,
  etiqueta: string,
  etiqueta_n: string | null,
  fecha_actd: string,
  fecha_contrato: string,
  fecha_firma: string | null,
  fecha_nac: string,
  firma_digital: string | null,
  id_contrato: string,
  id_direccion: string,
  id_emp: string,
  id_estrato: string,
  id_franq: string,
  id_g_a: string,
  id_gf: string,
  id_iva: string,
  id_operador: string,
  id_persona: string,
  id_venta: string,
  inicial_doc: string,
  iniciales: string,
  ip: string,
  login_firma: string | null,
  logo_franq: string,
  mac: string,
  nombre: string,
  nombre_franq: string,
  nro_contrato: string,
  nro_contrato_ant: string | null,
  nro_cuenta_pago: string,
  num: number,
  obser_franq: string,
  observacion: string,
  ord: number,
  postel: string,
  pto: string,
  saldo: string,
  serie: string,
  serie_factura: string,
  serie_nro_cp: string | null,
  serie_p: string,
  serie_pago: string,
  sexo: string,
  sinc_integracion: boolean,
  status_contrato: string,
  taps: string | null,
  telefono: string,
  telefono4: string | null,
  telf_adic: string,
  telf_casa: string,
  tipo_abonado: string,
  tipo_cliente: string,
  tipo_fact: string,
  ultima_act: string,
  ultimo_corte: string | null,
  ultimo_pago: string | null,
  url_epayco: string | null,
  y: string | null
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
  public userState: EventEmitter<IUserSae> = new EventEmitter<IUserSae>()
  constructor(
    public http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _logs: LogErrorsService,
    private _consulta: ConsultasService,
    private security: SeguridadDatos,
  ) { }

  public getUser(): IUserSae | null {
    const userData = this._localStorageService.get('DataToRegister');
    return (userData && userData.DataUserSae) || null;
  }

  public getUsers(): IUserSae[] {
    const userData = this._localStorageService.get('DataToRegister');
    return (userData ? userData.DataUser : []).filter(userItem => userItem.status_contrato !== "ANULADO");
  }

  public selectUser(nro_contrato: string) {
    const users = this.getUsers();
    const user = users.find(userItem => userItem.nro_contrato === nro_contrato);

    if(user) {
      const userData = this._localStorageService.get("DataToRegister");
      
      this._localStorageService.set("DataToRegister", {
        ...userData,
        DataUserSae: user
      });

      return user;
    }
  }

  public registerUser(userInfo: UserInfo) {
    // this._localStorageService.removeAll();
    // this._createAccount(userInfo);
    this.login({ email: userInfo.email, password: userInfo.password, telefono: userInfo.phone })
    // TODO: STEVEN
    // this.userState.emit({ ...userInfo });
    return true;
  }

  // _createAccount(userInfo: UserInfo) {
  //   let user: any = userInfo
  //   const { identidad, nro_contrato } = user

  //   this._localStorageService.set('user', { ...userInfo});
  //   this._localStorageService.set('cedulaCliente', {
  //     Cedula: `${identidad} (${nro_contrato})`,
  //     NContrato: nro_contrato,
  //     id:1,
  //     identidad
  //   });
  // }

  public noRegisterdUser() {
    const users = this.getUser();
    if (!users) {
      return true;
    }
    return false;
  }

  public login(userInfo: { email: string, password: string, telefono: string }) {
    const registeredUser = this.getUser();
    if (!registeredUser) {
      return false;
    }
    // const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
    const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase());
    const validPhoneUser = (userInfo.telefono === registeredUser.telefono);
    if (!validUser && !validPhoneUser) {
      return false;
    }

    this._localStorageService.set('auth-user', userInfo);
    return true;
  }

  modifyUser(userInfo: {[key in keyof IUserSae]?: IUserSae[key]}) {
    const info = this._localStorageService.get("DataToRegister");
    let userInfoUpdate = info.DataUserSae = { 
      ...info.DataUserSae,
      ...userInfo
    };
    info.DataUser = info.DataUser.map((user: any) => {
      return user.id_contrato === userInfoUpdate.id_contrato ? userInfoUpdate : user
    });
    this._localStorageService.set("DataToRegister", info);
    this.userState.emit(userInfoUpdate);
  }

  public logout() {
    this._localStorageService.remove('auth-user');
  }

  public async loginCRM(email: string, password: string) {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    try {
      const responseCliente: any = await this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
      if (!responseCliente) {
        return { status: false };
      }

      this._localStorageService.set('HaveFibexP', responseCliente.fibex_play)

      return responseCliente;

    } catch (error) {
      this._logs.insertarError(error.message);
      return { status: false };
    }
  }

  public async resetPassword(email: string, password: string) {
    try {
      const response: any = await this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        email: null,
        password: null,
      }
    }
  }

  UpdateEmail(Cedula: any, Email: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        this.http.get(`${environment.apiCRM}UpdateEmail/?ci=${Cedula}&email=${Email}`).subscribe((ResUp: any) => {
          resolve(ResUp)
        }, (erro: any) => {
          reject(erro)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  AcualizarDatos(Cedula: any, Email: undefined | string, Phone?: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const consultaData: any = await this._consulta.GetSaldoCedula(Cedula).then((response: any) => {
          const body = {
            "Cedula": Cedula,
            "id_Contrato": response[0].id_contrato,
            "Email": Email,
            "Lic": "584120202020@c.us",
            "Telefono": Phone
          }

          // Encabezados de la petición
          const headersData = {
            directory: "SAE",
            method: `ActualizarDatos`,
            token: environment.TokenUpdate,
            platform: "App"
          };

          this.security.EncrypDataHash(headersData).then((headers: any) => { // encabezados encriptados
            this.security.EncrypDataHash(body).then((DataBody: any) => { // encabezados encriptados
              this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResLog: any) => {
                resolve(this.security.Descryp(ResLog))
              }, err => { console.error(err) })
            }).catch((err) => console.error(err));
          }).catch((err) => console.error(err));

        });

      } catch (error) {
        reject(error)
      }
    })
  }

  public async verifyCedula(cedula: string) {
    try {
      const response: any = await this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        cedula: null,
      };
    }
  }

  public async querySecurityQuestions(email: string, opTipe: number = 2, preguntaUno: string = "", respuestaUno: string = "", preguntaDos: string = "", respuestaDos: string = "") {
    try {
      const response: any = await this.http.get(`${environment.apiSecurityQuestions}email=${email}&opTipe=${opTipe}&preguntaUno=${preguntaUno}&respuestaUno=${respuestaUno}&preguntaDos=${preguntaDos}&respuestaDos=${respuestaDos}`).toPromise();
      if (!response.status) {
        return {
          status: false,
          message: response.message,
          data: null,
        };
      }
      return response;

    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        message: "Las preguntas de seguridad no pudieron ser consultadas.",
        data: null,
      };
    }

  }

}